import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import {  HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Navbar from "./Navbar";
import TermsOfUSe from "./TermsOfUSe";
import { ToastContainer } from "react-toastify";

function App() {
  return (
   <>
   <HashRouter>
      <Navbar/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/termsOfUse" element={<TermsOfUSe/>}/>
    </Routes>
   </HashRouter>
   <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
   </>
  );
}

export default App;
