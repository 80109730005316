import React, { useEffect } from "react";
import Footer from "./Footer";

const TermsOfUSe = () => {
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);
  return (
    <>
      <div className="pt-24 p-10 mb-56">
        <p className="text-4xl font-bold">Website Terms of Use</p>
        <p className="mt-10">Version 1.0</p>
        <p className="mt-5 font-medium text-gray-500">
          The GPTOUTPUTDETECTOR website located at
          https://www.gptoutputdetector.com is a copyrighted work belonging to
          GPTOUTPUTDETECTOR. Certain features of the Site may be subject to
          additional guidelines, terms, or rules, which will be posted on the
          Site in connection with such features.
        </p>
        <p className="mt-5 font-medium text-gray-500">
          {" "}
          All such additional terms, guidelines, and rules are incorporated by
          reference into these Terms.
        </p>
        <p className="mt-5 font-medium text-gray-500">
          {" "}
          These Terms of Use described the legally binding terms and conditions
          that oversee your use of the Site.BY LOGGING INTO THE SITE, YOU ARE
          BEING COMPLIANT THAT THESE TERMS and you represent that you have the
          authority and capacity to enter into these Terms.YOU SHOULD BE AT
          LEAST 18 YEARS OF AGE TO ACCESS THE SITE.
        </p>
        <p className="mt-5 font-medium text-gray-500">
          {" "}
          IF YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO NOT LOG
          INTO AND/OR USE THE SITE. These terms require the use of arbitration
          Section 10.2 on an individual basis to resolve disputes and also limit
          the remedies available to you in the event of a dispute.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUSe;
