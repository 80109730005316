import { Spin, Button } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "./Footer";

const Home = () => {
  const [field, setField] = useState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);
  const getRequest = () => {
    if (!field) {
      toast.error("Write something in input");
    } else {
      setLoading(true);
      axios
        .post(
          "https://chatgpt1-huqe.onrender.com/api/third-party-endpoint/detectText",
          {
            input_text: field,
          }
        )
        .then((res) => {
          if (res.data.success) {
            setResult(res.data);
            toast.success("Result Fetched SuccessFully");
          }
        })
        .catch((err) => toast.error("Something went wrong!"))
        .finally(() => setLoading(false));
    }
  };
  return (
    <Spin className="flex justify-center items-center" spinning={false}>
      <div className="flex flex-col justify-center  items-center h-screen mx-3 md:mx-0 ">
        <h1 className="font-bold text-xl mt-14 text-center">
          GPT-4, ChatGPT & AI Detector by GPTOUTPUTDETECTOR: detect OpenAI text
        </h1>
        <h1 className="font-semibold text-lg text-center mb-8">
          GPTOUTPUTDETECTOR the most Advanced and Reliable Chat GPT, GPT4 & AI
          Content Detector
        </h1>
        <p className="font-medium text-sm mb-2 text-center">
          Paste your text here to see content is written by AI or Human{" "}
        </p>
        {result && (
          <p className="text-start text-red-500 mb-2">
            Hint:{result.data.feedback_message}
          </p>
        )}
        <textarea
          cols={12}
          rows={10}
          value={field}
          onChange={(e) => setField(e.target.value)}
          className="border border-black w-full md:w-1/2 rounded-md p-4"
          placeholder="Enter Some Text"
        />
        <div className="flex justify-around gap-10 mt-4 items-center float-right">
          <Button
            loading={loading}
            onClick={() => getRequest()}
            style={{ color: "white" }}
            className="bg-black h-10 border-none text-white"
          >
            Analyse Content ⚡
          </Button>
          <Button
            onClick={() => {
              setResult(null);
              setField("");
            }}
            style={{ color: "white" }}
            className="bg-black h-10 border-none text-white"
          >
            Reset 🗑️
          </Button>
        </div>
        {result ? (
          <>
            <p className="font-medium text-xs md:text-lg mb-8 bg-black text-white  p-4 rounded-md shadow shadow-gray-500 mt-4">
              {result.data.is_human_written} % HUMAN-GENERATED CONTENT
            </p>
            <p className="font-medium text-xs md:text-lg mb-8 bg-black text-white  p-4 rounded-md shadow shadow-gray-500 mt-2">
              {result.data.words_count} Word Count
            </p>
          </>
        ) : null}
      </div>
      <Footer />
    </Spin>
  );
};

export default Home;
