import { Button, Modal } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const Footer = () => {
  const [openModal, setOpenModal] = useState(false);
  const [text, setText] = useState("");
  return (
    <>
      <footer className=" bg-yellow-400 w-full p-4">
        <div className="flex cursor-pointer justify-start md:justify-around gap-6 items-start flex-wrap text-black font-bold text-sm">
          <div>
            <p>Puneet Agarwal</p>
            <p>Email I'd: a.puneet07@gmail.com</p>
          </div>
          <div>
            <p  onClick={() => setOpenModal(true)}>Our Policy</p>
            <NavLink to={"/termsOfUse"}>
              {" "}
              <p style={{color:"blue"}}>Terms of use</p>
            </NavLink>
          </div>
          <div>
            <p>Contact Us</p>
            <p>+919602473676</p>
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows={12}
              className="h-20 rounded-md focus:outline-none text-black p-3 mt-2"
              placeholder="Enter Message"
            />
            <br />
            <Button
            onClick={()=>{
              if(!text){
                toast.error("Write something in message box.")
              }else{
                toast.success("Message Sent Successfully.")
                setText("")
              }
            }}
              style={{ color: "white" }}
              className="w-full bg-black text-black border-none"
            >
              Submit
            </Button>
          </div>
        </div>
      </footer>
      {openModal && (
        <Modal
          footer={null}
          title={"Privacy & Policy"}
          onCancel={() => setOpenModal(false)}
          open={openModal}
        >
          <p>
            Welcome to GPtOutputDetector. At GPtOutputDetector, we respect your
            privacy and are committed to protecting your personal information.
            This Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you visit our website. By using our
            website, you consent to the practices described in this Privacy
            Policy. If you do not agree with our practices, please do not use
            our website.
          </p>
          <br />
          <p>
            Our website may use cookies and similar tracking technologies to
            enhance your browsing experience. You can control how cookies are
            used through your browser settings.
          </p>
          <div
            onClick={() => setOpenModal(false)}
            className="flex justify-end items-center"
          >
            <p className=" bg-yellow-300 p-3 text-black rounded-md font-semibold hover:bg-yellow-400 cursor-pointer">
              Go Back
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Footer;
