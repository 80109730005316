import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <div className="bg-yellow-400 z-[9999] h-auto fixed w-full p-4 tetx-white flex justify-between items-center flex-wrap gap-y-4 text-white font-bold">
   <div>
   <p className=" p-3 text-xs text-black ">
      GPTOutputDetector, A 2Microns Company
    </p>
   </div>
    <NavLink to="/">
    <p
      className="cursor-pointer text-black text-xs shadow-md shadow-gray-300 p-3 rounded-md hover:bg-yellow-300"
    >
      Home
    </p>
    </NavLink>
  </div>
  )
}

export default Navbar